import 'react-app-polyfill/ie11';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/find';
import 'core-js/features/array/fill';
import 'core-js/features/array/includes';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/trim-start';
import 'core-js/features/object/values';
import 'core-js/features/url-search-params';
//@ts-ignore
import at from 'array.prototype.at';

at.shim();
